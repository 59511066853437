import React from 'react';
import * as styles from './CardFive.module.scss'

const Wrapper = ({children, card, className}) => {
  return card.link ? <a href={card.link} className={className}>{children}</a> : <div className={className}>{children}</div>;
}

function CardFive({card, index}) {

  return (
    <Wrapper card={card} className={styles.wrapper}>
      {index !==  '' && <span>0{index + 1}</span>}
      <h3 className={styles.header}>{card.header}</h3>
      <p className={styles.desc}>{card.desc}</p>
      {card.link &&
        <span className={styles.arrow}>
          <svg className="block" width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path className="fill-current" fill-rule="evenodd"
                  d="M11.472 6c0-.304-.121-.596-.337-.811L6.268.322c-.45-.435-1.166-.428-1.608.014-.443.442-.45 1.158-.014 1.608l2.909 2.909H1.147C.514 4.853 0 5.366 0 6s.514 1.147 1.147 1.147h6.408l-2.91 2.909c-.434.45-.428 1.166.015 1.608.442.442 1.158.449 1.608.014l4.867-4.867c.216-.215.337-.507.337-.811z"></path>
          </svg>
        </span>
      }
    </Wrapper>
  );
}

export default CardFive;