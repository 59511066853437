import React from 'react';


const Layout = ({children, card}) => {
    return card.link ? <a href={card.link} className="card-service">{children}</a> : <div className="card-service">{children}</div>;
}

function CardsLikeService({card, index,}) {

    
    
    return(
        <Layout card={card}>
            <div className="card-service__bg">
                {card.img && <img src={card.img} alt={card.header} lazy/>}
            </div>
            <div className="card-service__index">
                <p>0{index}</p>
            </div>
            <div className="card-service__header">
                <h3>{card.header}</h3>
            </div>
            <div className="card-service__desc">
                <p>{card.desc}</p>
            </div>
            {card.link && <div className="card-service__link">
                <p>Подробнее</p>
            </div>}
        </Layout>   
    )
}

export default CardsLikeService;