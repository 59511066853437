import React from 'react';
import CardTwo from "../Molecules/CardTwo";
import CardsLikeService from '../ui/CardsLikeService';
import SectionHeader from "../Molecules/SectionHeader";

function OurServices({col, cardsData, children, desc, postHeader}) {
    let cols;
    col ? cols = `col-${col}` : cols="col-4";
    const cards = cardsData.map((item,index) =>{
        return <CardTwo card={item} index={index}/>
    })
    return (
        <article className="site_section our-services">
            <section className="container ">
                    <SectionHeader h2 bigText={desc}>{children}</SectionHeader>


                <div className={cols}>
                    {cards}
                </div>
            </section>
        </article>
    );
}

export default OurServices;