import React from 'react';
import SiteSection from "../../Atoms/SiteSection/SiteSection";
import {Col, Container, Row} from "../../Atoms/greeds";
import SectionHeader from "../../Molecules/SectionHeader";
import Tabs from "../../Molecules/Tabs";

const data = [
  {
    title: 'Возможно ли работать удаленно?',
    content: 'Да, в основном мы работаем удаленно со всеми клиентами, благодаря отлаженным процессам взаимодействия. Любой удобный способ связи - email, соц. сети и т.д. Такой вид сотрудничества упрощает работу и сокращает время на встречи, особенно когда время у Вас ограничено. Будьте уверены - удаленная работа с нами так же эфективна как и личные встречи, только следить за работой Вам будет в разы удобнее:)'
  },
  {
    title: 'А что у вас со сроками?',
    content: 'Всегда соблюдаем сроки и прописываем их в договоре. Так-же если сайт и клиенты нужны еще вчера.... Ведем разработку короткими итерациями*. *Итеративная разработка - это когда сайт делится на множество независимых частей. Каждой частью можно пользоваться отдельно, что позволяет сайту приносить результаты еще на этапе разработки.'
  },
  {
    title: 'Сайт за 100К???? Что так дорого?',
    content: 'Мы не предлагаем вам покупать "Сайт за 100+ т.р.". Наш продукт это интернет маркетинг, строящийся вокруг сайта. Все исследования, что мы проводим для разработки, помогут вам не только с продажами в интернете, но и с офлайн продажами, помогут понят боли  ваших клинтов, что позволит улучшить: конечный продукт и его позиционирование. Также после разработки мы сопровождаем сайт и улучшаем маркетинг, помогая клинту достигать поставленных перед сайтом целей.'
  },
  {
    title: 'Можем ли мы с вами заключить договор и работать официально?',
    content: 'Со всеми клиентами работаем строго по договору, в котором прописываем условия, сроки, стоимость работы, а так же краткое техническое задание на разработку сайта.'
  },
  {
    title: 'Можно ли впоследствии нарастить функционал на сайт? Например, из сайта-визитки сделать интернет-магазин?',
    content: 'Да, функционал наших сайтов можно расширять до бесконечности.'
  },
  {
    title: 'Что нужно, чтобы начать работу?',
    content: 'Для начала работы нужно оставить заявку у нас на сайте, в ответ вам придет анкета, которую нужно будет отправить в обратном сообщении. Анкета поможет нам и Вам понять каким вы видите свой сайт, а так же структурировать информации, рассчитать итоговую стоимость и приступить к разработке сайта.'
  },
];

function Faq(props) {
  return (
    <SiteSection>
      <Container>
        <SectionHeader
          h2
          bigText="Часто задаваемые вопросы"
        >FAQ</SectionHeader>
        <Row>
          <Col lg={7}>
            <Tabs data={data}/>
          </Col>
          <Col lg={5}>
            <img src="../../imgs/faq.svg" alt="Часто задаваемые вопросы"/>
          </Col>
        </Row>

      </Container>
    </SiteSection>
  );
}

export default Faq;