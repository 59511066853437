import React from 'react';

import SectionHeader from "../Molecules/SectionHeader";
import {Col, Container, Row} from "../Atoms/greeds";
import CardFive from "../Molecules/CardFive/CardFive";

function OurServicesLite({cardsData, children, desc}) {

  const cards = cardsData.map( (item, key) => <Col className="mb-32" lg={4} md={6}><CardFive card={item} key={key} index={key}/></Col>)
    return (
        <article className="site_section our-services">
          <Container>
            <SectionHeader h2 bigText={desc}>{children}</SectionHeader>
            <Row>
              {cards}
            </Row>
          </Container>
        </article>
    );
}

export default OurServicesLite;