import React from 'react';
import "../../scss/main.scss"
import Header from '../../components/header';
import Main from '../../components/main';
import PageHeader from '../../components/Organism/PageHeader/PageHeader';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer/Footer';
import OurServices from '../../components/OurServices/OurServices';
import OurServicesLite from '../../components/OurServices/OurServicesLite';

import StartWork from '../../components/forms/StartWork';
import Testimonials from '../../components/Organism/testimonials/testimonials';
import Portfolio from "../../components/Organism/Portfolio/Portfolio";
import Benefits from "../../components/Organism/benefits/benefits";
import Cta from "../../components/Organism/Cta/Cta";
import PayAndPrice from "../../components/Organism/PayAndPrice/PayAndPrice";
import TextAndPicture from "../../components/Organism/TextAndPicture/TextAndPicture";
import SectionHeader from "../../components/Molecules/SectionHeader";
import Faq from "../../components/Organism/Faq/Faq";

function siteCreate(props) {
  const form = <StartWork/>;
    return (
        <>
            <Helmet>
                <title>Разрабатываем сайты ориентаруясь на целевую аудиторию</title>
                <meta name="description" content="Создание, разработка и продвижение сайтов любой сложности по всей России ⭐ Заказать раскрутку веб-сайта в ТОП!" />
            </Helmet>
            <Header/>
            <Main>
              <PageHeader h1={`Разрабатываем\nи дорабатываем сайты`}
                  p={"Строим стратегии роста продаж через интернет маркетинг. Улучшаем маркетинговые показатели, ориентируясь на анализ вашего бизнеса и целевой аудитории"} modalButton
                   modalContent={form}/>
              <Benefits/>
              <OurServicesLite col={2} cardsData={[
                {
                  header:"Лендинги",
                  desc:'',
                  link:"../create-sites/landing-page/",
                },
                {
                  header:"Корпоративные сайты",
                  desc:'',
                  link:"../create-sites/corporate/",
                },
                {
                  header:"Интернет-магазины",
                  desc:'',
                  link:"../create-sites/e-commerce/",
                },
                {
                  header:"Тех-поддержка сайтов",
                  desc:'',
                  link:"../support/",
                },
                {
                  header:"Контекстная реклама",
                  desc:'',
                  link:"../context-advertising/",
                },


              ]}
                               desc="Услуги разработки и продвижения">Мы разрабатываем и поддерживаем сайты, делаем эффективный интернет маркетинг, настраиваем рекламу</OurServicesLite>

              <OurServices col={2} cardsData={[
                    {
                      header:"Разработка стратегии",
                      desc:"Изучаем продукт, рынок, спрос, аудиторию. Готовим стратегию интернет-маркетинга",
                      img:"../../imgs/Strata.svg",
                    },
                    {
                      header:"Изучаем пути клиентов",
                      desc:"Ищем пути клиентов на сайт, для каждой стадии подбираем поисковые запросы.",
                      img:"../../imgs/CJM.svg",
                    },
                    {
                      header:"Формируем УТП",
                      desc:"Прорабатываем целевые аудитории для подготовки УТП под каждый сегмент.",
                      img:"../../imgs/utp.svg",
                    },
                    {
                      header:"Проектируем интерфейс",
                      desc:"Разрабатываем текстовый прототип. Его собирают маркетологи, а не копирайтеры!",
                      img:"../../imgs/Interfaces.svg",
                    },
                    {
                      header:"Дизайн сайта",
                      desc:"Так как мы тщательно проработали прототип, макеты рисуются быстрее чем в классической веб-студии",
                      img:"../../imgs/Design.svg",
                    },
                    {
                      header:"Верстка и программирование",
                      desc:"Собственный отдел разработки из 10 человек гарантирует качество кода и скорость работы сайта.",
                      img:"../../imgs/develop.svg",
                    },

                  ]}
                  desc="Процесс разработки сайта">Этапы работы</OurServices>
              <Cta/>
              <OurServices col={3} cardsData={[
                {
                  header:"Техническая поддержка",
                  desc:"Мониторим сайт 24/7. Чтобы не случилось так, что реклама запущена, а сайт не работает.",
                  img:"",
                },
                {
                  header:"SEO-оптимизация",
                  desc:"Мы смотрим на SEO, как на один из важнейших инструментов продвижения в долгосрочной перспективе.",
                  img:"",
                },
                {
                  header:"Рекламные кампании",
                  desc:"Чтобы сайт приносил клиентов, нужно приводить на него посетителей. Мы настраиваем контекстную рекламу и таргетинг",
                  img:"",
                },

              ]}
                           desc="Что происходит после разработки">Поддержка маркетинга и сайта</OurServices>
              <TextAndPicture
                header="Гибкие условия оплаты"
                postHeader="Для каждого клиента мы можем подобрать подходящее ему решение, как в техническом так и в ценовом плане"
                desc={`Вся разработка делится на этапы. Поэтому мы можем позволить разделить общую сумму проекта на несколько платежей.\n
                Стоимость индивидуальной разработки начинается от 77 500 р.`}
                img="../../img/yoga.svg"
              />
              <Portfolio/>
              <Faq/>





                                
                                
            </Main>
            <Footer/>
        </>
);
}

export default siteCreate;