import React, {useState} from 'react';
import * as styles from './Tabs.module.scss'



function Tabs({data}) {
  const [active, setActive] = useState(0);
  const changeTab = ({target}) => {
    if (active == target.dataset.index){
      setActive(-1)
    } else {
      setActive(target.dataset.index)
    }
  };
  return (
    <>
      {data.map((item,i) => (
        <div className={`${styles.wrap} ${i == active && styles.active}`}>
          <div className={`${styles.tabKey} ${i == active && styles.tabKeyActive}`} data-index={i} onClick={changeTab}>
            {item.title}
          </div>
          <div className={`${styles.tab} ${i == active && styles.tabActive}`}>
            <p>
              {item.content}
            </p>
          </div>
          </div>
      ))}

    </>
  );
}

export default Tabs;