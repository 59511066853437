import React from 'react';

function SiteSection({className, header, desc, children}) {
    return (
        <section className={"site_section " + (className ? className : "")}>
            <div className="container">
                <div class="article__header">
                    {header && <h2>{header}</h2>}
                    {desc && <p>{desc}</p>}
                </div>
                {children}
            </div>
        </section>
    );
}

export default SiteSection;