import React from 'react';
import * as styles from './CardTwo.module.scss';


const Layout = ({children, card, className}) => {
    return card.link ? <a href={card.link} className={`${styles.cardService} ${className}`}>{children}</a> : <div className={`${styles.cardService} ${className}`}>{children}</div>;
}

function CardTwo({card, index, className}) {



    return(
        <Layout card={card} className={className}>
            <div className={styles.cardService__bg}>
                {card.img && <img src={card.img} alt={card.header} lazy/>}
            </div>
            <div className={styles.cardService__index}>
                <p>0{index + 1}</p>
            </div>
            <div className={styles.cardService__header}>
                <h3>{card.header}</h3>
            </div>
            <div className={styles.cardService__desc}>
                <p>{card.desc}</p>
            </div>
            {card.link && <div className={styles.cardService__link}>
                <p>Подробнее</p>
            </div>}
        </Layout>
    )
}

export default CardTwo;