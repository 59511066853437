import React from 'react';
import * as style from "./TextAndPicture.module.scss"
import SiteSection from "../../ui/SiteSection";
import {Col, Container, Row} from "../../Atoms/greeds";
import SectionHeader from "../../Molecules/SectionHeader";

const TextAndPicture = ({header, postHeader, desc, img}) => {
  return (
    <SiteSection>
      <Container>
        <Row>
          <Col md={6} className={style.leftColumn}>
            <SectionHeader bigText={header} className={style.header}>{postHeader}</SectionHeader>
            <p>
              {desc}
            </p>
          </Col>
          <Col md={6}>
            <img src={img} alt={header}/>
          </Col>
        </Row>
      </Container>
    </SiteSection>
  );
};

export default TextAndPicture;